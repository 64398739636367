import { Controller } from "@hotwired/stimulus"
import bodymovin from "lottie-web"

import Stimulus from "../../shared/utils/stimulus"

class Animations extends Controller {
  static targets = [`stage`]
  #animation

  connect() {
    const animationName = this.stageTarget.dataset.animationName
    this.#animation = bodymovin.loadAnimation({
      container: this.stageTarget,
      renderer: `svg`,
      loop: true,
      autoplay: true,
      path: `/animations/${animationName}.json`,
    })
  }

  disconnect() {
    this.#animation.destroy()
  }
}

Stimulus.register(`animations`, Animations)
