import { Controller } from "@hotwired/stimulus"
import fitty from "fitty"
import { createPopper } from "@popperjs/core"

import Stimulus from "../../shared/utils/stimulus"

const formatAmount = (amount) =>
  new Intl.NumberFormat(`sv-SE`, {
    currency: `SEK`,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(amount)

class Calculator extends Controller {
  static targets = [
    `calculatedDeposit`,
    `calculatedInterest`,
    `capitalInput`,
    `capitalValue`,
    `savingsInput`,
    `savingsValue`,
    `yearsInput`,
    `yearsValue`,
    `yearsUnit`,
    `happyNumber`,
    `interestRate`,
    `accountType`,
    `rateType`,
    `secondaryInputs`,
    `tooltipIcon`,
    `tooltipContent`,
  ]

  #popperInstance = null
  static values = {
    inputs: Array,
    rate: Number,
    floatingAccountYears: Array,
    fixedAccountYears: Array,
    floatingAccountCapitalDefault: Number,
    fixedAccountCapitalDefault: Number,
    fixedAccountCapitalMin: Number,
  }

  connect() {
    fitty(this.happyNumberTarget, { maxSize: 36, minSize: 8 })
    this.input({ ignoreValueTarget: false })
  }

  input = (e) => {
    const capital = parseInt(this.capitalInputTarget.value || 0)
    const savings = parseInt(this.savingsInputTarget.value || 0)
    const isFloating = this.accountTypeTarget.value === `floating`
    const accountYears = isFloating
      ? this.floatingAccountYearsValue
      : this.fixedAccountYearsValue
    const years = accountYears[parseInt(this.yearsInputTarget.value || 0)]

    let total = capital
    let interest = 0

    if (isFloating) {
      // Calculate monthly interest rate
      const monthlyRate = this.rateValue / 100 / 12

      // Loop for each month over the years
      for (let month = 0; month < years * 12; month++) {
        total += savings // add monthly savings at the start of the month
        total *= 1 + monthlyRate // apply monthly interest
      }
      interest = total - (capital + savings * years * 12) // Total interest earned
    } else {
      // Fixed term calculation using simple interest formula
      const months = years * 12
      interest = (((capital * this.rateValue) / 100) * months) / 12 // Calculate total interest earned over the period
      total = capital + interest // Total includes the initial deposit and earned interest
    }

    const totalFormatted = formatAmount(total) // Formatting total amount to display
    const interestFormatted = formatAmount(interest) // Formatting only interest

    this.calculatedDepositTargets.forEach(
      (t) => (t.innerHTML = totalFormatted) // Displaying total amount
    )

    this.calculatedInterestTargets.forEach(
      (t) => (t.innerHTML = interestFormatted) // Displaying interest
    )

    if (!e?.ignoreValueTarget) {
      this.updateValueTargets({ isFloating, years })
    }
  }

  updateValueTargets = ({ isFloating, years }) => {
    this.inputsValue.forEach((name) => {
      const valueTarget = this[`${name}ValueTarget`]
      const inputTarget = this[`${name}InputTarget`]
      const unitTarget = this[`${name}UnitTarget`]

      if (valueTarget && inputTarget) {
        let unit
        let value

        if (name === `years`) {
          unit = years < 1 || !isFloating ? `månader` : `år`
          value = unit === `månader` ? years * 12 : years
        } else {
          unit = `år`
          value = inputTarget.value
        }

        if (valueTarget instanceof HTMLInputElement) {
          valueTarget.value = formatAmount(inputTarget.value)
          valueTarget.style.width = `${inputTarget.value.length + 2}ch`
        } else {
          valueTarget.innerHTML = new Intl.NumberFormat(
            document.documentElement.lang
          ).format(value)
          unitTarget.innerHTML = unit
        }
      }
    })
  }

  updateInterestRate() {
    const selectedOption = this.accountTypeTarget.selectedOptions[0]
    const selectedChoice = this.accountTypeTarget.__CHOICES_OPTIONS.find(
      (choice) => choice.value === selectedOption.value
    )
    const rate = selectedChoice.rate
    const isFloating = selectedOption.value === `floating`

    this.interestRateTarget.textContent = `${rate}%`
    this.rateTypeTarget.textContent = isFloating ? `Rörlig ränta` : `Fast ränta`
    this.rateValue = parseFloat(rate)

    const savingsInput = this.savingsInputTarget
    const savingsValue = this.savingsValueTarget
    const savingsControl = savingsInput.closest(`.-control`)
    const secondaryInputs = this.secondaryInputsTargets
    const capitalInput = this.capitalInputTarget
    const capitalValueInput = this.capitalValueTarget

    const yearsInput = this.yearsInputTarget
    const yearsControl = yearsInput.closest(`.-control`)

    if (isFloating) {
      savingsInput.value = 500
      savingsInput.disabled = false
      savingsValue.value = 500
      savingsValue.disabled = false
      savingsControl.classList.remove(`disabled`)
      savingsControl.classList.remove(`without-slider-icon`)
      this.yearsInputTarget.max = 11
      capitalInput.value = this.floatingAccountCapitalDefaultValue
      yearsInput.value = 6
      yearsInput.disabled = false
      yearsControl.classList.remove(`without-slider-icon`)
    } else {
      yearsInput.disabled = true
      yearsControl.classList.add(`without-slider-icon`)

      savingsInput.value = 0
      savingsInput.disabled = true
      savingsValue.value = 0
      savingsValue.disabled = true
      savingsControl.classList.add(`disabled`)
      savingsControl.classList.add(`without-slider-icon`)
      this.yearsInputTarget.max = 4
      secondaryInputs.value = false
      yearsInput.value = selectedChoice.index - 1
      capitalInput.value = this.fixedAccountCapitalDefaultValue
      capitalInput.min = this.fixedAccountCapitalMinValue
      capitalValueInput.min = this.fixedAccountCapitalMinValue
    }

    this.triggerInputEvent(this.yearsInputTarget)
    this.triggerInputEvent(this.savingsInputTarget)
    this.triggerInputEvent(this.capitalInputTarget)
    this.input()
  }

  triggerInputEvent(element) {
    const event = new Event(`input`, { bubbles: true })
    element.dispatchEvent(event)
  }

  secondaryInput = (e) => {
    const name = e.target.name.replace(`_secondary`, ``)
    const valueTarget = this[`${name}ValueTarget`]
    const inputTarget = this[`${name}InputTarget`]

    if (valueTarget instanceof HTMLInputElement && inputTarget) {
      if (valueTarget.validity.valid) {
        inputTarget.value = valueTarget.value

        // eslint-disable-next-line no-irregular-whitespace
        const newValue = valueTarget.value.replaceAll(` `, ``)

        const finalValue = isNaN(parseInt(newValue)) ? 0 : parseInt(newValue)

        if (
          finalValue >= parseInt(valueTarget.min) &&
          finalValue <= parseInt(valueTarget.max)
        ) {
          // Update the input field with the formatted value
          valueTarget.value = formatAmount(finalValue)
          inputTarget.value = Number(finalValue)
        }

        if (finalValue < parseInt(valueTarget.min)) {
          valueTarget.value = formatAmount(valueTarget.min)
          inputTarget.value = Number(valueTarget.min)
        }

        if (finalValue > parseInt(valueTarget.max)) {
          valueTarget.value = formatAmount(valueTarget.max)
          inputTarget.value = Number(valueTarget.max)
        }

        const manualEvent = new Event(`input`, {
          bubbles: true,
        })
        manualEvent.ignoreValueTarget = true
        inputTarget.dispatchEvent(manualEvent)
      }

      valueTarget.style.width = `${(valueTarget.value.length || 1) + 2}ch`
      valueTarget.reportValidity()
    }
  }

  showTooltip = () => {
    this.tooltipContentTarget.style.display = `block`
    this.#popperInstance = createPopper(
      this.tooltipIconTarget,
      this.tooltipContentTarget,
      {
        placement: `top`,
        modifiers: [
          {
            name: `offset`,
            options: {
              offset: [0, 8],
            },
          },
          {
            name: `arrow`,
            options: {
              element:
                this.tooltipContentTarget.querySelector(`.tooltip-arrow`),
            },
          },
        ],
      }
    )
  }

  hideTooltip = () => {
    this.tooltipContentTarget.style.display = `none`
    if (this.#popperInstance) {
      this.#popperInstance.destroy()
      this.#popperInstance = null
    }
  }
}

Stimulus.register(`calculator`, Calculator)
